import axios from "axios";


//===========//

export const getStartupfundSection1 = async () => {
    try {
      const res = await axios.get("https://yeaapi.uniqdigi.online/startupfundsection1/");
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }


export const getStartupfundSection2 = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/startupfundsection2/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

