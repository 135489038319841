import React, { useState, useEffect } from 'react'
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';

import { getStartupfundSection1, getStartupfundSection2 } from "../services/startupfund.service";

const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function StartupFund()
{

  const [StartupfundSection1, setStartupfundSection1] = useState([]);
  useEffect(() => {
    const fetchStartupfundSection1 = async () => {
      const { data, error } = await getStartupfundSection1();
      if (error) {
        console.log(error);
      } else {
        setStartupfundSection1(data);
      }
    }
    fetchStartupfundSection1();
  }, []);


  const [StartupfundSection2, setStartupfundSection2] = useState([]);
  useEffect(() => {
    const fetchStartupfundSection2 = async () => {
      const { data, error } = await getStartupfundSection2();
      if (error) {
        console.log(error);
      } else {
        setStartupfundSection2(data);
      }
    }
    fetchStartupfundSection2();
  }, []);


return (
<>
<Header />
<Breadcrumb title="Startup Fund" />

<section className="ptb-60  gray">
    <div className="container">
    { StartupfundSection1.map((data, index) => ( 
      <div key={index} className="row align-items-center justify-content-between">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">{data.title}</h2>
                <span className="animate-border mb-3"></span>
              </div>
              <div className="text mb-3">
                <p>{data.description}</p>

              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <figure className="image bx-shadow">
              <img src={BASE_API_URL + '/uploads/startupfund/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
            </figure>
          </div>
        </div>
      </div>
      ))}
    </div>
  </section>


  <section className="space">
        <div className="container">
          <div className="row align-items-center justify-content-between">
          
            <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">	
              <div className="m-spaced">
                
                <div className="uli-list-features">
                  <ul>
                  { StartupfundSection2.map((data, index) => ( 
                    <li key={index}>
                    <span className="connector"></span>
                      <div className="list-uiyt">
                        <div className="list-iobk">
                        <span className="eael-feature-list-icon fl-icon-0">
                          <img src={BASE_API_URL + '/uploads/startupfund/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
                          </span>
                        </div>
                        <div className="list-uiyt-capt">
                          <h5>{data.title}</h5>
                          <p>{data.description}</p>
                        </div>
                      </div>
                    </li>
                    ))}
                    
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />






			
    

   </>
);
}

export default StartupFund;