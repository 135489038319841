import React, { useState, useEffect } from 'react'
import { getBusinesses } from "../services/business.service";
import { getIndustries } from "../services/industry.service";

const BASE_API_URL = "https://yeaapi.uniqdigi.online";
function BusinessesListing()
{

  const [businessess, setBusinessess] = useState([]);
  useEffect(() => {
    const fetchBusinessess = async () => {
      const { data, error } = await getBusinesses();      
      if (error) {
        console.log(error);
      } else {
        setBusinessess(data);
      }
    };
    fetchBusinessess();
  }, []);

  const [industries, setIndustries] = useState([]);

  useEffect(() => {
    const fetchBusinessess = async () => {
      const { data, error } = await getIndustries();      
      if (error) {
        console.log(error);
      } else {
         setIndustries(data);
      }
    };
    fetchBusinessess();
  }, []);

return (
<>
<section className="ptb-30 gray">
<div className="container">
  <div className="row">
    <div className="col-xl-12 col-lg-12 col-md-12 col-12">
      <div className="sec_title position-relative text-center mb-2">
        <h2 className="ft-bold theme-cl">Businesses</h2>
        <span className="animate-border mb-3"></span>
      </div>
    </div>
  </div>
  
  <div className="row justify-content-center align-items-center">
						
						<div className="col-xl-12 col-lg-12 col-md-12 col-12">
							<div className="Goodup-all-drp text-center mb-3">
								
								<div className="Goodup-single-drp small text-center my-auto">
									<div className="btn-group">
										<button type="button" className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">Industries</button>
										<ul className="dropdown-menu">
											<li><a className="dropdown-item" href={"/businesses/"}>All</a></li>
                              {industries.map((data, index) => (
											<li key={index}><a className="dropdown-item" href={"/businesses/industry/" + data.industryID}>{data.name}</a></li>
                              ))}
							  
										</ul>
									</div>
								</div>
							
							</div>
						</div>
						
					</div>

  <div className="row justify-content-center d-flex">
    
  {businessess.map((data, index) => (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-6 d-flex" key={index}>
      <div className="Goodup-grid-wrap border">
        <div className="Goodup-grid-upper">
        <a href={"/business/" + data.businessID} className="d-block text-center m-auto">
          <div className="Goodup-grid-thumb">
            
              <img src={BASE_API_URL + '/uploads/businesses/' + data.thumbnailImage} className="img-fluid" alt="" />
           
          </div>
          </a>
        </div>
        <div className="Goodup-grid-fl-wrap">
          <div className="Goodup-caption px-2 py-2">
           
            <h4 className="mb-0 ft-medium medium">
              <a href={"/business/" + data.businessID} className="text-dark fs-md">{data.business_name}
              </a>
            </h4>
            <div className="Goodup-middle-caption mt-2">
              <div className="Goodup-location">
                <i className="fas fa-map-marker-alt"></i>{data.city}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
))}

  </div>
</div>
</section>
</>
);
}

export default BusinessesListing;