import axios from "axios";

export const businessVerify = async (business) => {
  try {
    const res = await axios.post("https://yeaapi.uniqdigi.online/business/verify/", business);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const getBusinesses = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/businesses/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getMemberBusinesses = async (memberID) => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/businesses/member/" + memberID);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBusiness = async (business) => {
  try {
    const res = await axios.post("https://yeaapi.uniqdigi.online/businesses/create/", business);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateBusiness = async (business, businessId) => {
  try {
    const res = await axios.put(
      "https://yeaapi.uniqdigi.online/businesses/update/" + businessId,
      business
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteBusiness = async (businessId) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.uniqdigi.online/businesses/delete/" + businessId
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getBusinessById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/businesses/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};


export const uploadBusinessThumbnail = async (formData) => {
  try {
    const res = await axios.post(
      "https://yeaapi.uniqdigi.online/businesses/thumbnailupload/",
      formData
    );
    return res.data
      
  } catch (err) {
    console.log(err);
    return {error: err.message};
  }
};