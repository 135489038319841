import React, { useState, useEffect } from 'react';
import NewsListing from './NewsListing';
import MembersListing from './MembersListing';
import EventsListing from './EventsListing'
import HeaderTransparent from './HeaderTransparent';
import Footer from './Footer';
import { getBanners } from "../services/banner.service";
import { getHomeSection2, getHomeSection3, getHomeSection4, getHomeSection5 } from "../services/home.service";

const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function Home()
{

  const [HomeSection2, setHomeSection2] = useState([]);
  useEffect(() => {
    const fetchHomeSection2 = async () => {
      const { data, error } = await getHomeSection2();
      if (error) {
        console.log(error);
      } else {
        setHomeSection2(data);
      }
    }
    fetchHomeSection2();
  }, []);

  const [HomeSection3, setHomeSection3] = useState([]);
  useEffect(() => {
    const fetchHomeSection3 = async () => {
      const { data, error } = await getHomeSection3();
      if (error) {
        console.log(error);
      } else {
        setHomeSection3(data);
      }
    }
    fetchHomeSection3();
  }, []);


  const [HomeSection4, setHomeSection4] = useState([]);
  useEffect(() => {
    const fetchHomeSection4 = async () => {
      const { data, error } = await getHomeSection4();
      if (error) {
        console.log(error);
      } else {
        setHomeSection4(data);
      }
    }
    fetchHomeSection4();
  }, []);


  const [HomeSection5, setHomeSection5] = useState([]);
  useEffect(() => {
    const fetchHomeSection5 = async () => {
      const { data, error } = await getHomeSection5();
      if (error) {
        console.log(error);
      } else {
        setHomeSection5(data);
      }
    }
    fetchHomeSection5();
  }, []);

  const [banners, setBanners] = useState([]);
  useEffect(() => {
    const fetchBanners = async () => {
      const { data, error } = await getBanners();
      if (error) {
        console.log(error);
      } else {
        setBanners(data);
      }
    };
    fetchBanners();
  }, []);


return (
<>

<HeaderTransparent />
  <div className="clearfix"></div>


  <section id="carouselExampleCaptions" className="carousel slide p-0" data-bs-ride="carousel">
    <div className="carousel-inner">
    {banners.map((data, index) => (
      <div key={index} className="carousel-item active">
        <img src={BASE_API_URL + '/uploads/banners/' + data.thumbnailImage} className="d-block w-100" alt="{data.title}" />
        <div className="carousel-caption d-none d-md-block">
          <h4 className="theme-color">{data.description}</h4>
          <h2 className="text-white mb-3 text-capitalize font-5rem">{data.title}</h2>
          <a href="/about-us" className="btn bg-theme text-light rounded mx-2">Read More <i className="lni lni-arrow-right "></i>
          </a>
        </div>
      </div>
       ))}
    
   
    </div>
    <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="prev">
      <span className="carousel-control-prev-icon" aria-hidden="true" />
      <span className="visually-hidden">Previous</span>
    </button>
    <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide="next">
      <span className="carousel-control-next-icon" aria-hidden="true" />
      <span className="visually-hidden">Next</span>
    </button>
  </section>
  <section className="p-0 mt-md-n2">
    <div className="container">
      <div className="row justify-content-center">
      { HomeSection2.map((data, index) => ( 
        <div key={index} className="col-xl-4 col-lg-4 col-md-12 col-sm-12 d-flex">
          <div className="wrk-pro-box first bg-dark rounded-4 ">
            <div className="wrk-pro-box-icon mb-3">
            <img src={BASE_API_URL + '/uploads/home/' + data.thumbnailImage} alt={data.title} style={{maxHeight:'75px'}} />
            </div>
            <div className="wrk-pro-box-caption">
              <h4 className="theme-color-2">{data.title}</h4>
              <p className="text-white">{data.description}</p>
            </div>
          </div>
        </div>
        ))}
        
      </div>
    </div>
  </section>
  <section className="ptb-60">
    <div className="container">
    { HomeSection3.map((data, index) => ( 
      <div key={index} className="row align-items-center justify-content-between">
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">{data.title}</h2>
                <span className="animate-border mb-3"></span>
              </div>
              <div className="text mb-3">
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-lg-5 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <figure className="image bx-shadow">
              <img src={BASE_API_URL + '/uploads/home/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
            </figure>
          </div>
        </div>
      </div>
      ))}
    </div>
  </section>
  <section className="bg-dark ptb-60">
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
          <div className="sec_title position-relative text-center mb-2">
            <h2 className="ft-bold text-white pb-3">Forging a Brighter Entrepreneurial Tomorrow</h2>
            <span className="animate-border mb-3 m-auto"></span>
          </div>
        </div>
      </div>
      <div className="row justify-content-center">
      { HomeSection4.map((data, index) => ( 
        <div key={index} className="col-xl-6 col-lg-6 col-md-12 col-sm-12 d-flex">
          <div className="wrk-pro-box first rounded-4 bx-shadow">
            <div className="wrk-pro-box-icon">
              <img src={BASE_API_URL + '/uploads/home/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
            </div>
            <div className="wrk-pro-box-caption">
              <h4 className="theme-color-2">{data.title}</h4>
              <p className="text-white">{data.description}</p>
            </div>
          </div>
        </div>
        ))}
     
      </div>
    </div>
  </section>
  <section className="ptb-60" style={{background:"url(assets/images/light-bg.jpg) repeat"}}>
    <div className="container">
    { HomeSection5.map((data, index) => ( 
      <div key={index} className="row align-items-center justify-content-between">
        <div className="col-lg-5 col-md-12 col-sm-12 image-column">
          <div className="image-box">
            <figure className="image bx-shadow">
            <img src={BASE_API_URL + '/uploads/home/' + data.thumbnailImage} alt={data.title} className="img-fluid rounded" />
            </figure>
          </div>
        </div>
        <div className="col-lg-6 col-md-12 col-sm-12 content-column">
          <div className="content_block_2 pr-3 py-4">
            <div className="content-box">
              <div className="sec-title light">
                <h2 className="ft-bold">{data.title}</h2>
                <span className="animate-border mb-3"></span>
              </div>
              <div className="text mb-3">
                <p>{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      ))}
    </div>
  </section>

  

 


  <EventsListing />

<MembersListing memberType="Board Member" sectionTitle="Meet our distinguished Board Members" />



<NewsListing />
 
		



<Footer />
 

    
   </>
);
}

export default Home;