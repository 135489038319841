import React, { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { getMemberById } from "../services/member.service";
import { getMemberBusinesses } from "../services/business.service";
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';
import moment from 'moment'

const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function MemberInfo()
{
    const { id } = useParams();
    const [memberData, setMemberData] = useState({});

  useEffect(() => {
    const getMember = async () => {
      const memberData  = await getMemberById(id);      
      setMemberData(memberData.data[0]);
    };
    getMember();
  }, [id]);


  const [BusinessData, setBusinesses] = useState([]);
    useEffect(() => {
      const fetchBusinesses = async () => {
        const { data, error } = await getMemberBusinesses(id);
        if (error) {
          console.log(error);
        } else {
          setBusinesses(data);
        }
      };
      fetchBusinesses();
    }, [id]);



      return (
        <>
        <Header />
        <Breadcrumb title="Members"/>

        <section className="goodup-dashboard-wrap gray px-4 py-5">
          <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12">
                <div className="author-wrap-ngh">
                  
                  <div className="author-wrap-head-ngh">
                    <div className="author-wrap-ngh-thumb">
                      <img src={BASE_API_URL + "/uploads/members/" + memberData.profileImage} className="img-fluid circle" alt={memberData.full_name} />
                    </div>
                    <div className="author-wrap-ngh-info">
                      <h5>{memberData.full_name}</h5>
                      <div className="Goodup-location"><i className="fas fa-address-book" />{memberData.designation}</div>
                    </div>
                  </div>
                  <div className="author-wrap-caption-ngh">
									<div className="author-wrap-jhyu-ngh">
										<ul>
                      { memberData.facebook_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={memberData.facebook_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-facebook-f"></i></a></div>
												</div>
											</li>
                      )}
                      { memberData.twitter_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={memberData.twitter_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-twitter"></i></a></div>
												</div>
											</li>
                      )}
                      { memberData.linkedin_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={memberData.linkedin_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-linkedin"></i></a></div>
												</div>
											</li>
                      )}
                      { memberData.instagram_profile_URL && (
											<li>
												<div className="gdup-kvty">
													<div className="gdup-kvty-icon bg-light-sky"><a href={memberData.instagram_profile_URL} target="_blank" rel="noreferrer"><i className="fab fa-instagram"></i></a></div>
												</div>
											</li>
                      )}

										</ul>
									</div>
								
								</div>

             
                  <div className="author-wrap-footer-ngh">
                    <ul>
                      <li>
                        <div className="jhk-list-inf">
                          <div className="jhk-list-inf-ico my-auto"><i className="fas fa-envelope" /></div>
                          <div className="jhk-list-inf-caption my-auto"><h5>Email</h5><p className='pb-0 mb-0'>{memberData.email}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="jhk-list-inf">
                          <div className="jhk-list-inf-ico"><i className="fas fa-phone" /></div>
                          <div className="jhk-list-inf-caption"><h5>Phone</h5><p>{memberData.phone}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="jhk-list-inf">
                          <div className="jhk-list-inf-ico"><i className="fas fa-map-marker-alt" /></div>
                          <div className="jhk-list-inf-caption"><h5>Address</h5><p>{memberData.address}, {memberData.city}, {memberData.state} - {memberData.postal_code}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="jhk-list-inf">
                          <div className="jhk-list-inf-ico"><i className="fas fa-book" /></div>
                          <div className="jhk-list-inf-caption"><h5>Entrepreneur Type</h5><p>{memberData.entrepreneur_type}</p></div>
                        </div>
                      </li>
                      <li>
                        <div className="jhk-list-inf">
                          <div className="jhk-list-inf-ico"><i className="fas fa-calendar-alt" /></div>
                          <div className="jhk-list-inf-caption"><h5>Member Since</h5><p>{ moment(memberData.registeredDate).fromNow()}</p></div>
                        </div>
                      </li>
                    
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-8 col-lg-8 col-md-12">
              <div className="dashboard-list-wraps bg-white rounded mb-4">

              <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                    <div className="dashboard-list-wraps-flx">
                      <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-file-alt me-2 theme-cl fs-sm" />About Me</h4>	
                    </div>
                  </div>

              <div className="bg-white rounded mb-4">
                  <div className="jbd-01 p-3">
                    <div className="jbd-details">
                      
                      <div className="d-block">
                      <p dangerouslySetInnerHTML={{ __html: memberData.about_notes }}>
                      </p>

                  
                      </div>
                    </div>
                  </div>
                </div>

                  <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
                    <div className="dashboard-list-wraps-flx">
                      <h4 className="mb-0 ft-medium fs-md"><i className="fa fa-file-alt me-2 theme-cl fs-sm" />My Businesses</h4>	
                    </div>
                  </div>
                  <div className="dashboard-list-wraps-body py-3 px-3">
                    <div className="dashboard-listing-wraps">
                    {BusinessData.map((data, index) => (
                      <div className="dsd-single-listing-wraps row" key={index}>
                        <div className="col-4">
                          <div className="dsd-single-lst-thumb"><img src={BASE_API_URL + '/uploads/businesses/' + data.thumbnailImage} className="img-fluid" alt="" />
                          </div>
                        </div>	
                        <div className="dsd-single-lst-caption col-8">
                          <div className="dsd-single-lst-title"><h5>{data.business_name}</h5></div>
                          <span className="agd-location"><i className="lni lni-map-marker me-1" />{data.address}, {data.city}, {data.state} - {data.postal_code}</span>
                          
                          <div className="dsd-single-lst-footer">
                           
                            <a href={"/business/" + data.businessID} className="btn btn-view mr-1"><i className="fas fa-eye me-1" />View</a>
                            
                          </div>
                        </div>
                      </div>
                      ))}

                     
                    </div>
                  </div>
                </div>
              
              </div>
            </div>
          </div>
        </section>

        <Footer />
        </>
      );
    }
    export default MemberInfo;

