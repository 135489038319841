import axios from "axios";


//===========//

export const getAboutSection1 = async () => {
    try {
      const res = await axios.get("https://yeaapi.uniqdigi.online/aboutsection1/");
      return res.data;
    } catch (err) {
      console.log(err);
      return err;
    }
  }
  

  

export const getAboutSection2 = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/aboutsection2/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}



//-------------------//
export const getAboutSection3 = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/aboutsection3/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}



//===========//

export const getAboutSection4 = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/aboutsection4/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}

