import axios from "axios";

export const getIndustries = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/industries/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getIndustryBusinesses = async (insustryID) => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/industries/businesses/" + insustryID);
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
}
export const addIndustry = async (industry) => {
  try {
    const res = await axios.post("https://yeaapi.uniqdigi.online/industries/create/", industry);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateIndustry = async (industry, industryId) => {
  try {
    const res = await axios.put(
      "https://yeaapi.uniqdigi.online/industries/update/" + industryId,
      industry
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteIndustry = async (industryId) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.uniqdigi.online/industries/delete/" + industryId
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getIndustryById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/industries/industry/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};

