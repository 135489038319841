import axios from "axios";

export const getEventPhotos = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/eventphotos/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addEventPhoto = async (eventphoto) => {
  try {
    const res = await axios.post("https://yeaapi.uniqdigi.online/eventphotos/create/", eventphoto);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateEventPhoto = async (eventphoto, eventphotoID) => {
  try {
    const res = await axios.put(
      "https://yeaapi.uniqdigi.online/eventphotos/update/" + eventphotoID,
      eventphoto
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteEventPhoto = async (eventphotoID) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.uniqdigi.online/eventphotos/delete/" + eventphotoID
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getEventPhotoById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/eventphotos/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};


export const uploadEventPhoto = async (formData) => {
  try {
    const res = await axios.post(
      "https://yeaapi.uniqdigi.online/eventphotos/thumbnailupload/",
      formData
    );
    return res.data
      
  } catch (err) {
    console.log(err);
    return {error: err.message};
  }
};