
import React, {  useEffect, useState } from 'react'
import { getMemberById } from "../../services/member.service";
import { getMemberBusinesses } from "../../services/business.service";

import DashboardHeader from "./DashboardHeader";
import Header from '../Header';
import Footer from '../Footer';

const BASE_API_URL = "https://yeaapi.uniqdigi.online";

function DashboardMyProfile()
{

  const [memberData, setMemberData] = useState({});
    let memberID = localStorage.getItem('memberID');
  useEffect(() => {
    const getMember = async () => {
      const memberData  = await getMemberById(memberID);      
      setMemberData(memberData.data[0]);
    };
    getMember();
  }, [memberID]);

  const [BusinessData, setBusinesses] = useState([]);
  useEffect(() => {
    const fetchBusinesses = async () => {
      const { data, error } = await getMemberBusinesses(memberID);
      if (error) {
        console.log(error);
      } else {
        setBusinesses(data);
      }
    };
    fetchBusinesses();
  }, [memberID]);

return (
<>
<Header />
<DashboardHeader />



<section className="goodup-dashboard-wrap gray px-4 py-5">
  <div className="goodup-dashboard-content">
    <div className="dashboard-list-wraps rounded mb-4">
    <div className="container">
            <div className="row">
              <div className="col-xl-4 col-lg-4 col-md-12">
      <div className="dashboard-list-wraps-head br-bottom py-3 px-3 bg-white">
        <div className="dashboard-list-wraps-flx">
          <h4 className="mb-0 ft-medium fs-md">
            <i className="fa fa-file-alt me-2 theme-cl fs-sm" />Member Info
          </h4>
        </div>
      </div>
      <div className="bg-white rounded mb-4">
        <div className="jbd-01 p-2">
          <div className="jbd-details">
            <div className="author-wrap-footer-ngh">
              <ul className='row'>
                <li className='col-sm-12'>
                  <div className="jhk-list-inf">
                    <div className="jhk-list-inf-ico">
                      <i className="fas fa-envelope" />
                    </div>
                    <div className="jhk-list-inf-caption">
                      <h5>Email</h5>
                      <p>{memberData.email}</p>
                    </div>
                  </div>
                </li>
                <li className='col-sm-12'>
                  <div className="jhk-list-inf">
                    <div className="jhk-list-inf-ico">
                      <i className="fas fa-phone" />
                    </div>
                    <div className="jhk-list-inf-caption">
                      <h5>Phone</h5>
                      <p>{memberData.phone}</p>
                    </div>
                  </div>
                </li>
                <li className='col-sm-12'>
                  <div className="jhk-list-inf">
                    <div className="jhk-list-inf-ico">
                      <i className="fas fa-map-marker-alt" />
                    </div>
                    <div className="jhk-list-inf-caption">
                      <h5>Address</h5>
                      <p>{memberData.address}, {memberData.city}, {memberData.state} - {memberData.postal_code}</p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            
          </div>
        </div>
      </div>
      </div>
      <div className="col-xl-8 col-lg-8 col-md-12 bg-white">
      <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
              <div className="dashboard-list-wraps-flx">
                <h4 className="mb-0 ft-medium fs-md">
                  <i className="fa fa-file-alt me-2 theme-cl fs-sm"></i>About Me
                </h4>
              </div>
            </div>
            <div className="d-block pt-3 br-top">
              <p dangerouslySetInnerHTML={{ __html: memberData.about_notes }}></p>
            </div>
      <div className="dashboard-list-wraps-head br-bottom py-3 px-3">
        <div className="dashboard-list-wraps-flx">
          <h4 className="mb-0 ft-medium fs-md">
            <i className="fa fa-file-alt me-2 theme-cl fs-sm" />My Businesses
          </h4>
        </div>
      </div>
      <div className="dashboard-list-wraps-body py-3 px-3">
        <div className="dashboard-listing-wraps"> {BusinessData.map((data, index) => ( <div className="dsd-single-listing-wraps row" key={index}>
            <div className="col-4">
              <div className="dsd-single-lst-thumb">
                <img src={BASE_API_URL + '/uploads/businesses/' + data.thumbnailImage} className="img-fluid" alt="" />
              </div>
            </div>
            <div className="dsd-single-lst-caption col-8">
              <div className="dsd-single-lst-title">
                <h5>{data.business_name}</h5>
              </div>
              <span className="agd-location">
                <i className="lni lni-map-marker me-1" />{data.address}, {data.city}, {data.state} - {data.postal_code} </span>
              <div className="dsd-single-lst-footer">
                <a href={"/dashboard-my-business-edit/" + data.businessID} className="btn btn-edit mr-1">
                  <i className="fas fa-edit me-1" />Edit </a>
                <a href={"/business/" + data.businessID} className="btn btn-view mr-1">
                  <i className="fas fa-eye me-1" />View </a>
              </div>
            </div>
          </div> ))} </div>
      </div>
      </div>
      </div>
      </div>
    </div>
  </div>
</section>
      
<Footer />
    

   </>
);
}

export default DashboardMyProfile;