import React, { useState, useEffect } from 'react'
import { getEventPhotos } from "../services/event_photo.service";
import Breadcrumb from './Breadcumb';
import Header from './Header';
import Footer from './Footer';
const BASE_API_URL = "https://yeaapi.uniqdigi.online";
function EventGallery()
{

  const [eventphotos, setEventPhotos] = useState([]);
  useEffect(() => {
    const fetchEventPhotos = async () => {
      const { data, error } = await getEventPhotos();
      if (error) {
        console.log(error);
      } else {
        setEventPhotos(data);
      }
    };
    fetchEventPhotos();
  }, []);

return (
<>
<Header />
<Breadcrumb title="Event Gallery" />
<section className="ptb-30 gray">
<div className="container">
  <div className="row justify-content-center">
    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
      <div className="sec_title position-relative text-center mb-2">
        <h2 className="ft-bold">Event Gallery</h2>
        <span className="animate-border mb-3"></span>
      </div>
    </div>
  </div>
  <div className="row justify-content-center"> 
  {eventphotos.map((data, index) => ( 
     <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12 d-flex" key={index}>
      <div className="gup_blg_grid_box">
        <div className="gup_blg_grid_thumb">
        <a href={BASE_API_URL + '/uploads/events/photos/' + data.photo} className="mfp-gallery">
            <img src={BASE_API_URL + '/uploads/events/photos/' + data.photo} className="img-fluid" alt="YEA India"/>
          </a>
        </div>

        
      </div>
    </div> 
))}
    
  </div>
</div>
</section>

<Footer />
</>
);
}

export default EventGallery;