import axios from "axios";

export const getBlogs = async () => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/blogs/");
    return res.data;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const addBlog = async (blog) => {
  try {
    const res = await axios.post("https://yeaapi.uniqdigi.online/blogs/create/", blog);
    return res.data;
  } catch (err) {
    console.log(err);
    return { error: err };
  }
};

export const updateBlog = async (blog, blogId) => {
  try {
    const res = await axios.put(
      "https://yeaapi.uniqdigi.online/blogs/update/" + blogId,
      blog
    );
    return res.data;
  } catch (err) {
    return {
      error: err,
    };
  }
};
export const deleteBlog = async (blogId) => {
  try {
    
    const res = await axios.delete(
      "https://yeaapi.uniqdigi.online/blogs/delete/" + blogId
    );
    return res.data;
  } catch (err) {
    return { error: err };
  }
};
export const getBlogById = async (id) => {
  try {
    const res = await axios.get("https://yeaapi.uniqdigi.online/blogs/" + id);
    return res.data
  } catch (err) {
    return {error: err.message}
  }
};


export const uploadBlogThumbnail = async (formData) => {
  try {
    const res = await axios.post(
      "https://yeaapi.uniqdigi.online/blogs/thumbnailupload/",
      formData
    );
    return res.data
      
  } catch (err) {
    console.log(err);
    return {error: err.message};
  }
};